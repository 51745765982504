class Auth {
  login() {
    this.openPopup();
  }

  registration() {
    this.openPopup('&signup=1');
  }

  openPopup(signup = '') {
    window.EVA.showLogin()
    return false;
  }

  logout(redirectUrl = 'https://pvp.vkplay.ru/') {
    window.location.replace(
      `https://auth-ac.vkplay.ru/logout?continue=${redirectUrl}`,
    );
  }
}

export const auth = new Auth();
