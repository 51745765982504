<template>
  <div>
    <div id="shell"></div>
    <div ref="appShellRef" class="app-shell-ref">
      <slot />
    </div>
  </div>
</template>

<script>
import { i18n } from '@src/localization/config';

export default {
  name: 'AppShell',
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      scriptUrl: 'https://vkplay.ru/hotbox/eva/navigation.js',
      isScriptLoaded: false,
    };
  },

  computed: {
    isNoPvpProfile() {
      return this.$store.state.profile.profile.no_profile;
    },
    isShowProfileMenu() {
      return Boolean(this.$store.state.profile.profile.login);
    },

    menuItems() {
      const generalPages = [
        {
          id: 'index',
          label: i18n.t('actions.overview'),
          href: '/',
          onClick: this.handleNavItemClick,
        },
        {
          id: 'tournaments',
          label: i18n.t('menu.tournaments'),
          href: '/tournaments',
          onClick: this.handleNavItemClick,
        },
      ];

      if (this.isShowProfileMenu) {
        return [
          ...generalPages,
          {
            id: 'profile',
            label: i18n.t('tournaments.variant_my'),
            href: '/profile',
            onClick: this.handleNavItemClick,
            allowPartialMatch: true,
            include: [`/player/`]
          },
        ];
      }

      return generalPages;
    },
  },

  watch: {
    menuItems() {
      window.EVA.props.subnav = this.menuItems;
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.mountEmbeddedScript();
    },

    mountEmbeddedScript() {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = this.scriptUrl;
      scriptElement.onload = () => {
        window.EVA.init('shell', {
          preset: 'pvp',
          appNode: this.$refs.appShellRef,
          subnav: this.menuItems,
          includeSearch: ['pvp'],
        });
      };

      document.querySelector('body').append(scriptElement);
    },

    handleNavItemClick(e, name) {
      e.preventDefault();

      const basePageUrl = '/';
      window.EVA.props.currentPathname =
        name === 'index' ? basePageUrl : basePageUrl + name;

      if (name === 'profile') {
        if (this.isNoPvpProfile) {
          this.$router.push({ name: 'registration' });
          return;
        }

        this.$router.push('/profile');
        return;
      }

      this.$router.push({ name });
    },
  },
};
</script>
