<!-- eslint-disable vue/valid-template-root -->
<template></template>

<script>
export default {
  name: 'Feedback',
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      scriptUrl:
        'https://vkplay.ru/hotbox/feedback/feedback-nps.js',
      isScriptLoaded: false,
    };
  },
  watch: {
    isScriptLoaded: function () {
      this.run();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.mountEmbeddedFeedback();
    },
    mountEmbeddedFeedback() {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = this.scriptUrl;
      document.querySelector('body').append(scriptElement);

      scriptElement.onload = () => {
        window.Feedback.init({
          clientId: this.userId,
          surveys: [
            {
              surveyId: '70',
              delay: 3000,
              permille: 100,
              repeatAfterDays: 180,
              triggerEvents: [{ type: 'main_page' }],
            },
            {
              surveyId: '71',
              delay: 5000,
              permille: 50,
              repeatAfterDays: 180,
              triggerEvents: [{ type: 'tournament_page' }],
            },
            {
              surveyId: '72',
              delay: 5000,
              permille: 100,
              repeatAfterDays: 180,
              triggerEvents: [{ type: 'profile_page' }],
            },
          ],
          projectName: 'vkplay_desktop_web',
        });

        this.isScriptLoaded = true;
      };
    },
    triggerSurvey(pageName, timeout) {
      window.FeedbackEvents.triggerWithDelay(pageName, timeout);
    },
    run() {
      switch (this.$route.name) {
        case 'index':
          this.triggerSurvey('main_page', 5000);
          break;
        case 'tournament':
          this.triggerSurvey('tournament_page', 5000);
          break;
        case 'profile':
          this.triggerSurvey('profile_page', 5000);
          break;
      }
    },
  },
};
</script>
