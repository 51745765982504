/* eslint-disable */

import VueMeta from 'vue-meta';
import storage from '@utils/storage';
import store from '@state/store';

const profileRoutes = [
  {
    path: '',
    name: 'profile',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/Tournaments'
      ),
  },
  {
    path: 'teams',
    name: 'profile-teams',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/Teams'
      ),
  },
  {
    path: 'rewards',
    name: 'profile-rewards',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/Rewards/index'
      ),
    meta: {
      onlyForCurrentUser: true,
    },
  },
  {
    path: 'games',
    name: 'profile-games',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/Games'
      ),
  },
  {
    path: 'matches',
    name: 'profile-matches',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/Matches'
      ),
  },
  {
    path: 'ratings',
    name: 'profile-ratings',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/Ratings'
      ),
  },
  {
    path: 'terms',
    name: 'profile-terms',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/Terms'
      ),
    meta: {
      onlyForCurrentUser: true,
    },
  },
  {
    path: 'organizations',
    name: 'profile-organizations',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/Organizations'
      ),
    meta: {
      onlyForCurrentUser: true,
    },
  },
  {
    path: 'teamfinder',
    name: 'profile-jobfair',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/Jobfair'
      ),
    meta: {
      onlyForCurrentUser: true,
    },
  },
];

Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
});

const routes = [
  // AutoPayments
  {
    path: 'autopayments',
    name: 'autopayments',
    meta: {
      authRequired: true,
      profileRequired: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "autopayments" */ '@src/widgets/autoPayments'
      ),
  },
  // Minigames
  {
    path: 'minigames',
    name: 'minigames',
    component: () =>
      import(
        /* webpackChunkName: "minigames" */ '@src/views/Minigames/MinigamesPage.vue'
      ),
  },
  {
    path: 'minigames/tournaments/:id',
    name: 'minigames-tournament',
    component: () =>
      import(
        /* webpackChunkName: "minigame-tournament" */ '@src/views/Minigames/MinigamesTournamentPage.vue'
      ),
  },
  {
    path: 'minigames/tournaments/:id/playground',
    name: 'minigame-playground',
    component: () =>
      import(
        /* webpackChunkName: "minigame" */ '@src/views/Minigames/MinigamePage.vue'
      ),
  },

  // Tournaments
  {
    path: 'tournaments',
    name: 'tournaments',
    component: () =>
      import(
        /* webpackChunkName: "tournaments" */ '@views/Tournaments'
      ),
  },

  // User profile
  {
    path: 'player/:hash',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/Profile'
      ),
    children: profileRoutes,
  },
  {
    path: 'player/confirm/:hash',
    name: 'email-confirmation',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/EmailConfirmation'
      ),
    props: true,
  },

  // Current user profile
  {
    path: 'profile',
    name: 'pvp-profile',
    component: () =>
      import(
        /* webpackChunkName: "profile" */ '@views/Profile/Profile'
      ),
    children: profileRoutes.map((route) => ({
      ...route,
      name: `my-${route.name}`,
      meta: {
        ...route.meta,
        ignoreLocale: true,
        userProfileRoute: route.name,
        authRequired: true,
        profileRequired: true,
      },
    })),
  },

  // Login
  {
    path: 'login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "main" */ '@views/LoginPage'),
    meta: {
      authProhibited: true,
    },
  },

  // Registration
  {
    path: 'registration',
    component: () =>
      import(/* webpackChunkName: "profile" */ '@views/RegisterPage'),
    name: 'registration',
    meta: {
      authRequired: true,
      profileProhibited: true,
    },
  },

  // Auth
  {
    path: 'oauth',
    name: 'oauth',
    component: () =>
      import(/* webpackChunckName: "auth" */ '@views/Oauth'),
  },
  {
    path: 'authcheck',
    name: 'authcheck',
    component: () =>
      import(/* webpackChunckName: "auth" */ '@views/AuthCheck'),
  },

  // Profile edit
  {
    path: 'profile/edit',
    component: () =>
      import(/* webpackChunkName: "profile" */ '@views/Profile/Edit'),
    name: 'profile-edit',
    meta: {
      authRequired: true,
      profileRequired: true,
    },
  },

  // Accounts linking
  {
    path: 'profile/games/lesta',
    name: 'lesta-response',
    meta: {
      ignoreLocale: true,
    },
    component: () =>
      import(/* webpackChunkName: "main" */ '@views/LoadingPage'),
    beforeEnter(to, from, next) {
      api
        .get('/profile/game/lestacallback', {
          params: to.query,
        })
        .catch(({ error, errorData }) => {
          storage.setItem('gameLink.lesta.error', error);
          storage.setItem('gameLink.lesta.errorData', errorData);
        })
        .finally(() => {
          next(to.query.extra);
        });

      next();
    },
  },
  {
    path: 'profile/games/steam',
    name: 'steam-response',
    meta: {
      ignoreLocale: true,
    },
    component: () =>
      import(/* webpackChunkName: "main" */ '@views/LoadingPage'),
    beforeEnter(to, from, next) {
      api
        .get('/profile/game/steamcallback', {
          params: to.query,
        })
        .catch(({ error, errorData }) => {
          storage.setItem('gameLink.steam.error', error);
          storage.setItem('gameLink.steam.errorData', errorData);
        })
        .finally(() => {
          next(to.query.extra);
        });

      next();
    },
  },
  {
    path: 'profile/games/battlenet',
    name: 'battlenet-response',
    meta: {
      ignoreLocale: true,
    },
    component: () =>
      import(/* webpackChunkName: "main" */ '@views/LoadingPage'),
    beforeEnter(to, from, next) {
      api
        .post('/profile/game/battlenetcallback', {
          game: to.query.game,
          code: to.query.code,
          region: to.query.region,
          extra: to.query.extra,
        })
        .catch(({ error }) => {
          storage.setItem('gameLink.battlenet.error', error);
        })
        .finally(() => {
          next(to.query.extra);
        });

      next();
    },
  },
  {
    path: 'profile/fcs',
    name: 'fcs-response',
    meta: {
      ignoreLocale: true,
    },
    component: () =>
      import(/* webpackChunkName: "main" */ '@views/LoadingPage'),
    beforeEnter(to, from, next) {
      api
        .post('/profile/resf/callback', to.query)
        .catch(({ error }) => {
          storage.setItem('fcs.link.error', error);
        })
        .finally(() => {
          store.dispatch('profile/fetchCurrentProfile');
          next(to.query.extra);
        });

      next();
    },
  },

  // Tournament
  {
    path: 'tournament/:id',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "tournament" */ '@src/views/Tournament'
      ),
    children: [
      {
        path: '',
        name: 'tournament',
        component: () =>
          import(
            /* webpackChunkName: "tournament" */ '@views/Tournament/Info.vue'
          ),
      },
      {
        path: 'bracket',
        name: 'tournament-bracket',
        component: () =>
          import(
            /* webpackChunkName: "tournament" */ '@views/Tournament/Bracket.vue'
          ),
      },
      {
        path: 'schedule',
        name: 'tournament-schedule',
        component: () =>
          import(
            /* webpackChunkName: "tournament" */ '@views/Tournament/Schedule.vue'
          ),
      },
      {
        path: 'players',
        name: 'tournament-players',
        component: () =>
          import(
            /* webpackChunkName: "tournament" */ '@views/Tournament/Players.vue'
          ),
      },
      {
        path: 'rules',
        name: 'tournament-rules',
        component: () =>
          import(
            /* webpackChunkName: "tournament" */ '@views/Tournament/Rules.vue'
          ),
      },
      {
        path: 'participation',
        name: 'tournament-participation',
        component: () =>
          import(
            /* webpackChunkName: "tournament" */ '@views/Tournament/Participation.vue'
          ),
      },
      {
        path: 'contacts',
        name: 'tournament-contacts',
        component: () =>
          import(
            /* webpackChunkName: "tournament" */ '@views/Tournament/Contacts.vue'
          ),
      },
      {
        path: 'table',
        name: 'tournament-table',
        component: () =>
          import(
            /* webpackChunkName: "tournament" */ '@views/Tournament/Table.vue'
          ),
      },
    ],
  },
  {
    path: 'tournament/:id/bracket/embedded',
    name: 'embedded-tournament-bracket',
    component: () =>
      import(
        /* webpackChunkName: "EmbeddedBracket" */ '@views/Tournament/EmbeddedBracket.vue'
      ),
  },

  // News
  {
    path: 'news',
    name: 'news',
    component: () =>
      import(/* webpackChunkName: "news" */ '@views/News/NewsPage'),
  },
  {
    path: 'news/:id',
    name: 'news-single',
    component: () =>
      import(
        /* webpackChunkName: "news" */ '@views/News/NewsSinglePage'
      ),
    props: (route) => ({ id: Number(route.params.id) }),
  },

  // Videos
  {
    path: 'videos',
    name: 'videos',
    component: () =>
      import(
        /* webpackChunkName: "videos" */ '@views/Video/VideoListPage'
      ),
  },
  {
    path: 'videos/:id',
    name: 'video-item',
    component: () =>
      import(
        /* webpackChunkName: "videos" */ '@views/Video/VideoSinglePage'
      ),
    props: (route) => ({ id: Number(route.params.id) }),
  },

  // Game
  // {
  //   path: 'game/:id',
  //   name: 'game',
  //   component: () =>
  //     import(/* webpackChunkName: "game" */ '@src/views/Game/GamePage.vue'),
  // },

  // Organization
  {
    path: 'organization/:id',
    name: 'organization',
    component: () =>
      import(
        /* webpackChunkName: "organization" */ '@views/Organization'
      ),
    props: (route) => ({ id: Number(route.params.id) }),
  },
  {
    path: 'organization/:orgId/tournament/:tnId',
    component: () =>
      import(
        /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEdit'
      ),
    props: (route) => ({
      tnId: route.params.tnId,
      orgId: Number(route.params.orgId),
    }),
    children: [
      {
        path: '',
        name: 'tournament-edit-main',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditMain'
          ),
      },
      {
        path: 'description',
        name: 'tournament-edit',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditAbout'
          ),
      },
      {
        path: 'game',
        name: 'tournament-edit-game',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditGame'
          ),
      },
      {
        path: 'hubsettings',
        name: 'tournament-edit-match-making',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/MatchMaking/MatchMakingSettings'
          ),
      },
      {
        path: 'leaderboard',
        name: 'tournament-edit-leaderboard-list',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/MatchMaking/MatchMakingLeaderboardList'
          ),
      },
      {
        path: 'leaderboard/:id',
        name: 'leaderboard-settings',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/MatchMaking/MatchMakingLeaderboardSettings'
          ),
      },
      {
        path: 'grid',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditGrid'
          ),
        children: [
          {
            path: '',
            name: 'tournament-edit-grid',
            component: () =>
              import(
                /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditGridSettings'
              ),
          },
          {
            path: 'schedule',
            name: 'tournament-edit-grid-settings',
            component: () =>
              import(
                /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditGridSchedule'
              ),
          },
        ],
      },
      {
        path: 'other',
        name: 'tournament-edit-other',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditOther'
          ),
      },
      {
        path: 'sowing',
        name: 'tournament-edit-seeding',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditSeeding'
          ),
      },
      {
        path: 'teams',
        name: 'tournament-edit-teams',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditTeams'
          ),
      },
      {
        path: 'bans',
        name: 'tournament-edit-ban-list',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/MatchMaking/MatchMakingBanList'
          ),
      },
      {
        path: 'activities',
        name: 'tournament-edit-activities',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditActivities'
          ),
      },
      {
        path: 'administration',
        name: 'tournament-edit-match-control',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditMatchControl'
          ),
      },
      {
        path: 'participants',
        name: 'tournament-edit-participants',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentParticipants'
          ),
      },
      {
        path: 'heartstone-pick',
        name: 'tournament-edit-heartstone-pick',
        component: () =>
          import(
            /* webpackChunkName: "tournament-edit" */ '@views/Tournament/Edit/TournamentEditHeartstonePick'
          ),
      },
    ].map((item) => ({
      ...item,
      meta: {
        authRequired: true,
        profileRequired: true,
      },
    })),
  },

  // Rating
  {
    path: 'ratings',
    name: 'ratings',
    component: () =>
      import(
        /* webpackChunkName: "rating" */ '@views/Ratings/RatingsPage'
      ),
  },
  {
    path: 'ratings/:gameCode',
    name: 'ratings-game',
    component: () =>
      import(
        /* webpackChunkName: "rating" */ '@views/Ratings/RatingsPage'
      ),
    props: true,
  },
  {
    path: 'rating/:id',
    name: 'rating',
    component: () =>
      import(
        /* webpackChunkName: "rating" */ '@views/Ratings/Rating'
      ),
    props: true,
  },
  // Team
  {
    path: 'team/:hash',
    component: () =>
      import(/* webpackChunkName: "team" */ '@views/Team/Team.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'team',
        component: () =>
          import(
            /* webpackChunkName: "profile" */ '@views/Team/TeamRatings'
          ),
      },
      {
        path: 'tournaments',
        name: 'team-tournaments',
        component: () =>
          import(
            /* webpackChunkName: "profile" */ '@views/Team/TeamTournaments'
          ),
      },
      {
        path: 'match',
        name: 'team-match',
        component: () =>
          import(
            /* webpackChunkName: "profile" */ '@views/Team/TeamMatches'
          ),
      },
      {
        path: 'changes',
        name: 'team-changes',
        component: () =>
          import(
            /* webpackChunkName: "profile" */ '@views/Team/TeamChanges'
          ),
      },
    ],
  },
  {
    path: 'team/:hash/invite/:invitationHash',
    name: 'team-invitation',
    component: () =>
      import(/* webpackChunkName: "team" */ '@views/Team/Team.vue'),
    props: true,
    meta: {
      authRequired: true,
      profileRequired: true,
    },
  },

  // Match
  {
    path: 'match/:id',
    name: 'match',
    component: () =>
      import(
        /* webpackChunkName: "match" */ '@src/views/Match/MatchPage'
      ),
    props: true,
  },

  // Terms
  {
    path: 'terms',
    name: 'terms',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "terms" */ '@views/Terms/Terms.vue'
      ),
  },
  {
    path: 'terms/:id',
    name: 'term',
    component: () =>
      import(/* webpackChunkName: "terms" */ '@views/Terms/Term.vue'),
    props: true,
  },
  {
    path: 'terms/:id/:version',
    name: 'term-version',
    component: () =>
      import(/* webpackChunkName: "terms" */ '@views/Terms/Term.vue'),
    props: true,
  },

  // Teamfinder
  {
    path: 'teamfinder',
    name: 'jobfair',
    component: () =>
      import(/* webpackChunkName: "jobfair" */ '@views/Jobfair.vue'),
    props: true,
  },

  // hub-match
  {
    path: 'hub/:id/match/:matchId',
    name: 'hub-match',
    component: () =>
      import(
        /* webpackChunkName: "HubMatch" */ '@src/views/Lobby/HubMatch.vue'
      ),
    props: true,
  },

  // Main
  {
    path: '',
    name: 'index',
    component: () =>
      import(/* webpackChunkName: "main" */ '@views/MainPage'),
  },

  // Errors page
  {
    path: '404',
    name: 'not-found',
    component: () =>
      import(/* webpackChunkName: "main" */ '@views/NotFoundPage'),
  },
  {
    path: 'error',
    name: 'error',
    meta: {
      ignoreAuth: true,
    },
    props: true,
    component: () =>
      import(/* webpackChunkName: "main" */ '@views/ErrorPage'),
  },
  // TODO удалить после тестирования новой реги.
  {
    path: 'test/rega/:id',
    name: 'test',
    component: () =>
      import(
        /* webpackChunkName: "TestRegistrationPage" */ '@views/TestRegistrationPage'
      ),
  },
  // TODO удалить после тестирования рефатора привязок игр.
  {
    path: 'test/gamelink',
    name: 'test-gamelink',
    component: () =>
      import(
        /* webpackChunkName: "TestGameLink" */ '@views/TestGameLink'
      ),
  },
  {
    path: 'tournament/:id/bracket/print',
    name: 'bracket-print',
    component: () =>
      import(
        /* webpackChunkName: "tournament" */ '@views/Tournament/BracketPrint.vue'
      ),
    props: true,
  },
  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "main" */ '@views/NotFoundPage'),
  },
];

const updateRouteName = (item) => {
  const route = Object.assign({}, item);
  if (route.name) {
    route.name = `locale-${route.name}`;
  }
  if (route.children) {
    route.children = route.children.map(updateRouteName);
  }
  return route;
};

export default [
  ...routes.map((route) => ({
    ...route,
    path: route.path === '*' ? route.path : `/${route.path}`,
  })),
];
