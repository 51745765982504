<template>
  <div class="main-layout">
    <main class="main-content" :class="contentCssClass">
      <loader
        v-if="isLoading"
        key="redundant-fix"
        class="loading-container"
        :size="64"
      />
      <slot v-else />
    </main>

    <section
      v-if="$slots['seo-text'] && !isLoading"
      class="pvp-container seo-text"
    >
      <div
        class="description-section"
        :class="{
          'description-section--short': isShowShortText,
        }"
      >
        <slot name="seo-text" />
      </div>
      <button
        v-if="footerSpoiler"
        class="description-section-more"
        @click="toggleMoreText"
      >
        {{ showMoreInnerText }}
      </button>
    </section>

    <match-notification />
    <registration-modal />
    <profile-create-modal-vkplay
      @registered="handleProfileCreateModalRegistered"
    />
    <platform-terms-modal />
    <terms-modal />
    <profile-locked-modal />
    <Feedback :user-id="profile.ac_id" />
  </div>
</template>

<script>
import RegistrationModal from '@components/TournamentComponents/Registration/RegistrationModal.vue';
import TermsModal from '@components/TermsModal.vue';
import ProfileCreateModalVkplay from '@components/ProfileComponents/ProfileCreateModalVkplay.vue';
import PlatformTermsModal from '@components/ProfileComponents/PlatformTermsModal.vue';
import MatchNotification from '@components/Match/CheckinNotification.vue';
import ProfileLockedModal from '@components/ProfileComponents/ProfileLockedModal.vue';
import Feedback from '@components/OutsideComponents/Feedback.vue';

export default {
  name: 'MainLayout',
  components: {
    ProfileLockedModal,
    MatchNotification,
    PlatformTermsModal,
    TermsModal,
    RegistrationModal,
    ProfileCreateModalVkplay,
    Feedback,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    footerSpoiler: {
      type: Boolean,
      default: false,
    },
    flex: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollListener: undefined,
      canShowScrollTop: false,
      isShowMoreText: false,
    };
  },
  computed: {
    ...mapState('profile', ['profile']),
    ...mapState('profile', ['isWithoutGameAccountLinkModal']),

    contentCssClass() {
      return { 'main-content--flex': this.flex };
    },

    showMoreInnerText() {
      return this.isShowMoreText
        ? this.$t('actions.hide')
        : this.$t('actions.show_more');
    },

    isShowShortText() {
      return !this.isShowMoreText && this.footerSpoiler;
    },
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollListener, false);
  },

  methods: {
    ...mapActions('tournamentRegistration', ['startRegistration']),

    handleProfileCreateModalRegistered() {
      if (this.isWithoutGameAccountLinkModal) {
        this.$store.commit(
          'profile/SET_IS_WITHOUT_GAME_ACCOUNT_LINK_MODAL',
          false,
        );

        window.location.reload(); // убрать когда научим компонент турнира обновлять данные по хабы после дореги
        return;
      }
      this.startRegistration();
    },

    toggleMoreText() {
      this.isShowMoreText = !this.isShowMoreText;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  main {
    position: relative;
    flex: 1 0 100%;

    .loading-container {
      padding-top: 200px;
    }
  }

  &.padded {
    main {
      margin-top: $headerHeight;
    }
  }

  .description-section {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid rgba(white, 0.05);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #979797;

    &--short {
      max-height: 72px;
      overflow: hidden;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
    }
  }
}

.description-section-more {
  color: #2290df;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 0;
  border: 0;
  background: none;
}

.seo-text,
.main-content {
  padding-bottom: 24px;
}

.main-content {
  min-height: calc(100vh - 100px);

  &--flex {
    display: flex;
  }
}
</style>
